import request from '@/utils/request'

// 分享砍价新建提交
export function subNewRipoff (data) {
    return request({
        url: '/customer-service/shareBargain',
        method: 'post',
        data
    })
}

// 分享砍价编辑提交
export function subEditRipoff (data) {
    return request({
        url: '/customer-service/shareBargain',
        method: 'put',
        data
    })
}

// 根据Id获取分享砍价详情
export function getRipoffDetail (id) {
    return request({
        url: '/customer-service/shareBargain/' + id,
        method: 'get'
    })
}

// 获取所有奖品列表
export function getAllGiftList (data) {
    return request({
        url: '/goods-service/goods/info/list',
        method: 'post',
        data
    })
}


// 禁用分享砍价
export function disableRipoff (id, status) {
    return request({
        url: 'customer-service/shareBargain/disable/' + id + '/' + status,
        method: 'put'
    })
}

// 获取分享砍价列表
export function getRipoffList (data) {
    return request({
        url: 'customer-service/shareBargain/query',
        method: 'post',
        data
    })
}

// 根据Id获取帮砍记录列表
export function getRipoffRecordList (data) {
    return request({
        url: "/customer-service/shareBargain/logById",
        method: 'post',
        data
    })
}

// 根据Id获取帮砍记录详情
export function getRipoffRecordDetail (params) {
    return request({
        url: "/customer-service/shareBargain/seeUserByActivityId",
        method: 'post',
        params
    })
}